import React from "react";
import "./PricingPerPic.css";
import { useTranslation } from "react-i18next";
import pricePerPicData from "../../../data/pricinPerPic.json";


const PricingPerPic = ({currency,HandleOneImageCartSelected}) => {
  const iconURL = (imgName) => {
    return require(`../../../assets/img/icons/${imgName}`);
  };
  const pricingPerPic = pricePerPicData[currency];
  const getCurrencySymbol = (currencyCode) => {
    switch (currencyCode) {
      case 'USD':
        return '$USD';
      case 'EUR':
        return '€';
      case 'AUD':
        return '$AUD';
      case 'CAD':
        return '$CAD';
      case 'GBP':
        return '£';
      case 'NZD':
        return '$NZD';
      default:
        return ''; // Si la devise n'est pas trouvée, retournez une chaîne vide
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="container-pricing-per-pic" onClick={HandleOneImageCartSelected}>
        <img
          className="icon-pricing-per-pic"
          style={{ margin: "15px", width: "50px" }}
          src={iconURL("perpic.svg")}
          alt="icon pricing-per-pic"
        />
        <div className="infos-pricing-per-pic">
          <h1 className="title-pricing-per-pic">
           {pricingPerPic}{getCurrencySymbol(currency)} {t(`price_per_photo.title`)}
          </h1>
          <div className="list-pricing-per-pic">
            <span>{t(`price_per_photo.p1`)}</span>
            <span>{t(`price_per_photo.p2`)}</span>
          </div>
        </div>
      </div>
      <span className="choice">{t(`price_per_photo.other_choice`)}</span>
      <div className="">

{/* <h3 className=" text-[#FF9401] !mb-1 ">check out our Packages </h3> */}
<p className="!font-medium !text-sm text-center ">
{t(`price_per_photo.whyPackages`)}

</p>
</div>

    </>
  );
};

export default PricingPerPic;
