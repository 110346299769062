import React, { useEffect, useState } from "react";
import "./PricingPacks.css";
import { useTranslation } from "react-i18next";
import Orders from "../../../data/planCurrency.json";
import WindowWidthComponent from "../../WindowWidthComponent";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PricingPerPic from "./PricingPerPic";

const PricingPacks = ({ getOrderInfos, currency,HandleOneImageCartSelected }) => {
  const iconURL = (imgName) => {
    return require(`../../../assets/img/icons/${imgName}`);
  };

  const options = {
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay: false,
    nav: true,
    loop: false,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 1,
        nav: true,
      },
      1000: {
        items: 3,
        nav: true,
      },
    },
  };

  const { t } = useTranslation();
  const windowWidth = WindowWidthComponent();
  const [currentCurrency, setCurrentCurrency] = useState(currency);
  const [packType, setPackType] = useState(null);
  const [packPrice, setPackPrice] = useState(0);

  const updatePackPrice = (order) => {
    const newPrice = order.price[currentCurrency] || 0;
    setPackPrice(newPrice);
    // Call getOrderInfos to update the total price
    if (packType) {
      getOrderInfos({
        price: newPrice,
        credit: order.nbrPics,
        checked: true,
        name: order.name,
      });
    }
  };

  const handleClickPackage = (order) => {
    getOrderInfos({
      price: order.price[currency], // Use the price corresponding to the selected currency
      credit: order.nbrPics,
      checked: true,
      name: order.name,
    });
    setPackType(order.name);
    updatePackPrice(order); // Update the price when package is clicked
  };

  useEffect(() => {
    // Update pack price when currency changes
    if (packType) {
      const selectedOrder = Orders.find((order) => order.name === packType);
      updatePackPrice(selectedOrder);
    }
  }, [currentCurrency]);

  useEffect(() => {
    setCurrentCurrency(currency);
  }, [currency]);

  const getCurrencySymbol = (currencyCode) => {
    switch (currencyCode) {
      case "USD":
        return "$USD";
      case "EUR":
        return "€";
      case "AUD":
        return "$AUD";
      case "CAD":
        return "$CAD";
      case "GBP":
        return "£";
      case "NZD":
        return "$NZD";
      default:
        return ""; // Return an empty string if currency code is not found
    }
  };


  const formatPrice = (price) => {
    if (Number.isInteger(price)) {
      return price.toString(); // Si c'est un entier, retourne le prix tel quel
    } else {
      return price.toFixed(2); // Si c'est un décimal, retourne le prix avec 2 chiffres après le point décimal
    }
  };
  
  const pricingElement = (classnameContainer, classnameBtn, order) => {
    return (
      <div className={classnameContainer} key={order.id}>
        {/* Ribbon */}
        {order.id === 3 && (
          <div
            className="ribbon left"
            style={{
              top: "calc(25% - 100px)",
              "--f": "10px",
              "--r": "5px",
              background: "#FF9401",
            }}
          >
            {t(`order-form.virtual-staging-element.best`)}
          </div>
        )}
  
        {/* Plan cost */}
        <div className="plan-cost">
          <img
            style={{ margin: "15px", width: order.id === 4 ? "64px" : "50px" }}
            src={iconURL(order.icon)}
            alt={`${order.alt}`}
          />
          <h3 className="Pack-title">
            {t(
              `order-form.virtual-staging-element.components-element.comp${order.id}.title`
            )}
          </h3>
          <div className="plan-price">
           
            <span className="priceperpic">
              <span className="text-2xl" >{`${formatPrice(order.priceperpic[currentCurrency])}`}{" "}</span>
              <span className="">{getCurrencySymbol(
                currentCurrency
              )}</span>
              <span className="photo">/photo</span>
            </span>
          
          </div>
        </div>
  
        {/* Plan features */}
        <ul className="plan-features">
          <li>
          <span className="highlighted-first">
             
             {t(
               `order-form.virtual-staging-element.components-element.comp${order.id}.specific-service.highlighted`
             )} <span className="text-xs"> /year </span>
           </span>
          <div className="price">
              <span className=" font-bold">{`${formatPrice(order.price [currentCurrency])}`}</span> <span  > {getCurrencySymbol(
              currentCurrency
            )}</span>
              </div> {/* Use the price corresponding to the selected currency */}
           
          </li>
          <li>
          <span className="highlighted">
              -{" "}
             No automatic Renewal
            </span>{" "}
          </li>
          <li>
         
            <span className="highlighted">
              -{" "}
              {t(
                `order-form.virtual-staging-element.commun-services.s1.highlighted`
              )}
            </span>{" "}
            <span>
              {t(
                `order-form.virtual-staging-element.commun-services.s1.normal`
              )}
            </span>
          </li>
          <li>
            <span className="highlighted">
              -{" "}
              {t(
                `order-form.virtual-staging-element.commun-services.s2.highlighted`
              )}
            </span>{" "}
            <span>
              {t(
                `order-form.virtual-staging-element.commun-services.s2.normal`
              )}
            </span>
          </li>
          <li>
            <span className="highlighted">
              -{" "}
              {t(
                `order-form.virtual-staging-element.commun-services.s3.highlighted`
              )}
            </span>{" "}
            <span>
              {t(
                `order-form.virtual-staging-element.commun-services.s3.normal`
              )}
            </span>
          </li>
        </ul>
  
        {/* Plan select button */}
        <div className={classnameBtn}>
          <a onClick={() => handleClickPackage(order)} className="cursor-pointer">
            {t(`order-form.virtual-staging-element.btn`)}
          </a>
        </div>
      </div>
    );
  };
  return (
    <>
    
      <PricingPerPic currency={currency} HandleOneImageCartSelected={HandleOneImageCartSelected} />
      {/* <div className="indications">
        <span>{t(`order-form.virtual-staging-element.text1`)}</span>
      </div> */}
     
      <div className="snip1214 gap-14 mt-36">
      
        {windowWidth <= 1280 ? (
          <OwlCarousel className="owl-theme" {...options}>
            {Orders.map((order) => (
              <div key={order.id}>
                {t(
                  `order-form.virtual-staging-element.components-element.comp${order.id}.title`
                ) === "Pro" ? (
                  <> {pricingElement("plan featured", "plan-select", order)} </>
                ) : (
                  <>
                    {" "}
                    {pricingElement(
                      "plan",
                      "plan-select-highlighted",
                      order
                    )}{" "}
                  </>
                )}
              </div>
            ))}
          </OwlCarousel>
        ) : (
          Orders.map((order) =>
            t(
              `order-form.virtual-staging-element.components-element.comp${order.id}.title`
            ) === "Pro" ? (
              <> {pricingElement("plan featured", "plan-select", order)} </>
            ) : (
              <> {pricingElement("plan", "plan-select-highlighted", order)} </>
            )
          )
        )}
      </div>

      {/* Indications */}
      
    </>
  );
};

export default PricingPacks;
