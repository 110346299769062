import React, { useEffect, useState } from 'react';
import { X } from 'lucide-react';

const BlackFriday = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Check localStorage to see if the popup should be displayed
    const popupClosed = sessionStorage.getItem('popupClosed');
    if (!popupClosed) {
      setShowPopup(true);
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
    // Save in sessionStorage to keep the popup closed until the tab is reopened
    sessionStorage.setItem('popupClosed', 'true');
  };

  return (
    showPopup && (
      <div className="fixed inset-0 z-10000 flex items-center justify-center bg-[#0000009d] backdrop-blur-sm">
        <div className=" w-[90%] bg-[#041c32] max-w-lg lg:max-w-2xl h-auto lg:h-2/3 flex flex-col items-center justify-center space-y-4  p-4 lg:p-8 rounded-lg shadow-lg relative">
          {/* Close Button */}
          <X
            className="absolute top-4 right-4 cursor-pointer z-10"
            size={30}
            color="#fff"
            onClick={handleClose}
          />
          <div className="z-10 text-center">
           
          </div>
          <h2 className="archivo-black-regular text-white text-6xl lg:text-7xl text-center capitalize">
          Your First Virtual Staging photo is <span className='uppercase' style={{textTransform:""}} > Free</span> !
          </h2>
          <h5 className="text-white text-lg lg:text-2xl z-10 text-center">
          Try it Now!
          </h5>
         
        </div>
      </div>
    )
  );
};

export default BlackFriday;
